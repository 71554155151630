import React from 'react'
import { Button } from '../Button/Button'

export const DisplayButton  = ({ value, label, type }) => {
  return (
    <div className="xl:mr-4 mr-1">
      <Button type={type}>
        {value}
      </Button>
      <span className="flex mt-4 justify-center">
        {label}
      </span>
    </div>
  )
}

