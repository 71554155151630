import { useEffect } from 'react'
import { Button } from '../components/Button/Button';
import Facebook from '../img/logo/facebook.png'
import Telegram from '../img/logo/telegram.png'
import Twitter from '../img/logo/twitter.png'
import { useHistory } from "react-router-dom"
import { useAccounts } from '../hooks/useAccounts';
import useWeb3  from "../hooks/useWeb3";
import moment from 'moment'
import ReactGA from 'react-ga'

export const  Whitepaper = () => {
  const { myAccount } = useAccounts()
  const [web3Val] = useWeb3()
  const history = useHistory();

  function manualConnectWeb3(){
    web3Val.initWeb3()
  }
  useEffect(() => {
    ReactGA.initialize('UA-198740041-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])
  const formatAddress = (acc) => {
    return `${acc.substr(0,4)}...${acc.substring(acc.length - 4, acc.length)}`
  }
  const redirectToIndex = () => {
    history.push('/')
  }
  return (
    <div>
      <div className="landing-section" >
        <div className="xl:pl-16 xl:pr-16 pl-4 pr-4">
          <div className="pt-4 flex justify-between items-center">
            <div className="logo gradient-text xl:ml-32 text-2xl mr-10">
              COOLDOWN
            </div>
            <div className="hidden xl:flex menu-bar text-xl">
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                Home
              </div>
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                Buy
              </div>
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                Chart
              </div>
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                About
              </div>
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                Roadmap
              </div>
              <div className="cursor-pointer mr-8">
                Whitepaper
              </div>
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                Community
              </div>
            </div>
            <Button type='primary' onClick={manualConnectWeb3}>
              {myAccount ? formatAddress(myAccount) : 'Connect Wallet'}
            </Button>
          </div>
          <div className="landing-content w- flex flex-col items-center justify-center">
            <div className="logo gradient-text xl:text-9xl text-7xl mt-32">
              COOLDOWN
            </div>
            
          </div>
        </div>
      </div>
      <div className="learn-more">
        <div className="pl-16 pr-16">
          <div className="content mt-20 xl:pl-48 xl:pr-48">
            <div className="content-header text-3xl pb-6 text-primary">
                What is Cooldown.finance ?
            </div>
            <ul classsName="list-disc">
              <li>Cooldown is autonomous yield and liquidity generation protocol, fair launched Defi project on Binance Smart Chain. On each transaction the protocol automatically distributes rewards to holders as well as auto-locks liquidity forever. The main feature to reduce volatility is cooldown system that allow users to sell Cooldown token only once a week. </li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
                How Cooldown system work?
            </div>
            <ul classsName="list-disc ">
              <li>The aim of Cooldown system is to avoid a sudden price drop.</li>
              <li>Cooldown tokens will be locked for 7 days, and users will be able to sell or transfer them once a week.</li>
              <li>After 7 days, Cooldown tokens can be sold or transferred to another address within 24 hours. </li>
              <li>Users can buy Cooldown tokens at any time, the selling time and Cooldown time will not change.</li>
              <li>In the other hand, once users sell all tokens, the selling time and Cooldown time will be reset. </li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
                Transaction fee
            </div>
            <ul classsName="list-disc ">
              <li>Each transactions ( buy, sell, or transfer ) is charged a 10% fee as seen below.</li>
              <li>9 % of each transaction automatically generate liquidity on Pancake Swap. ( transformed into Cooldown-BNB LP with 1 : 1 )</li>
              <li>1% of each transaction is automatically distributed to holders. ( The burned address is also holder helping deflate supply. )</li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
                Manual burn
            </div>
            <ul classsName="list-disc ">
              <li>50% of the total supply was sent to the blackhole address, as per tokenomic.</li>
              <li>The black hole address is also holder and receives transaction fee as well.</li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
              Tokenomics
            </div>
            <ul classsName="list-disc ">
              <li>Total supply : 1,000,000,000,000,000 (100%)</li>
              <li>Burned supply : 50%</li>
              <li>Liquidity : 45%</li>
              <li>Operation/marketing : 5%</li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
                Highly secured / Liquidity lock
            </div>
            <ul classsName="list-disc ">
              <li>To ensure that Cooldown is unrugglable, we have burned all the Liquidity Pool tokens (Cooldown/BNB LP) and renounced contract ownership.</li>
              <li>The burns are available on the blockchain and are transparent.</li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
                Fair launch
            </div>
            <ul classsName="list-disc ">
              <li>There is no pre-sale. We add all 45% of the token supply to LP on PancakeSwap and participate in the fair launch with everyone else. We will publish the listing date on PancakeSwap. There is no chance for the team or anyone can buy prior to the community. </li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
                Anti dumping whale
            </div>
            <ul classsName="list-disc ">
              <li>Transactions (sell/buy/transfer) that trade more than 0.5% of the total supply will be rejected. </li>
              <li>The main feature that can be used to counteract price dump rapidly is cooldown system. Just 1/7 of all users can sell on each day, So the maximum estimated price decrease is about 1/7 of total price dump as compare to other platform.  </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="roadmap bg-gray-100" >
        <div className="hidden xl:block">
          <div className="pl-16 pr-16 mt-20 pb-32 pt-20">
            <div className="gradient-text text-5xl text-center">
              Roadmap
            </div>
            <div className="block">
              <div class="arrow mt-8">
                <div class="line" />
                <div class="point" />
              </div>
            </div>
            <p>&nbsp;</p>
            <div className="flex">
              <div className="mr-5">
                <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button type="primary">
                  1 May 2021<br/>
                  Deployed token and contract ,
                  <br/>Liquidity locked , 
                  <br/>Burned 50% of total supply 
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button>
                  Q2 2021<br/>
                  Marketing launch<br/>
                  , Social media launch
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button type="primary">
                  Q2 2021<br/>
                  Coingecko & Coinmarketcap<br/>
                  listing
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button>
                  Q2 2021<br/>
                  COOLDOWN V.2 <br/>
                  ( lotto system ) <br/>
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button type="primary">
                  Coming soon
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="block xl:hidden" >
          <div className="bg-gray pt-10 pb-20 pl-4 pr-4 relative">
            <div className="gradient-text text-5xl text-center mb-20">
              Roadmap
            </div>
            <div class="vertical-arrow-no-height h-4/5 absolute">
              <div class="vertical-line" />
              <div class="vertical-point" />
            </div>
            <div className="mb-10">
              <Button type="primary">
                1 May 2021<br/>
                Deployed token and contract ,
                <br/>Liquidity locked , 
                <br/>Burned 50% of total supply 
              </Button>
            </div>
            <div className="mb-10">
              <Button>
                Q2 2021<br/>
                Marketing launch<br/>
                , Social media launch
              </Button>
            </div>
            <div className="mb-10">
              <Button type="primary">
                Q2 2021<br/>
                Coingecko & Coinmarketcap<br/>
                listing
              </Button>
            </div>
            <div className="mb-10">
              <Button>
                Q2 2021<br/>
                COOLDOWN V.2 <br/>
                ( lotto system ) <br/>
              </Button>
            </div>
            <div className="mb-10">
              <Button type="primary">
                coming soon
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="contact" >
        <div className="gradient-button text-4xl text-white p-12 rounded-xl">
          Join our community
        </div>
        <div className="pl-16 pr-16 mt-20">
          <div className="xl:pl-48 xl:pr-48">
            <span className="font-bold text-xl text-gray-700">
              Get in touch
            </span>
            <div className="flex justify-center items-center mt-12">
                <img className="mr-4" src={Facebook} />
                <img className="mr-4" src={Twitter} />
                <img className="mr-4" src={Telegram} />
            </div>
          </div>
        </div>
      </div>
      <div className="footer mt-10 pt-6 pb-6 font-semibold bg-gray-300 text-center text-gray-500">
        THE 7 Copyright 2021 - All Rights Reserved
      </div>
    </div>
  );
}

