import React from 'react'

export const DescriptionBox  = ({ img, label }) => {
  return (
    <div className="box mr-5">
        <img src={img} />
        <span>
            {label}
        </span>
    </div>
  )
}