import './App.css';
import './index.css';
import { Web3Provider } from './hooks/useWeb3'
import { Index } from './page/Index'
import { Whitepaper } from './page/Whitepaper'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
function App() {
  return (
    <Web3Provider>
      <Router>
        <Switch>
          <Route path="/whitepaper" >
            <Whitepaper />
          </Route>
          <Route path="/" >
            <Index />
          </Route>
        </Switch>
      </Router>
    </Web3Provider>
  );
}

export default App;
