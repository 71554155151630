import { useRef, useCallback, useState, useEffect } from 'react'
import { Button } from '../components/Button/Button';
import { DisplayButton } from '../components/DisplayButton/DisplayButton';
import LiveChart from '../img/livechart.png'
import System from '../img/logo/system.png'
import Tokenomic from '../img/logo/tokenomic.png'
import Solution from '../img/logo/solution.png'
import Mail from '../img/logo/mail.png'
import Telegram from '../img/logo/telegram.png'
import Twitter from '../img/logo/twitter.png'
import CopyIcon from '../img/logo/copy.png'
import { DescriptionBox } from '../components/DescriptionBox/DescriptionBox';
import { useAccounts } from '../hooks/useAccounts';
import { useThe7Contract } from '../hooks/useThe7Contract';
import { THE_7_ADDRESS, WBNB_CONTRACT_ADDRESS, BUSD_CONTRACT_ADDRESS, PANCAKE_LP_ADDRESS } from '../config/contract'
import { usePancakeRouterContract } from '../hooks/usePancakeRouterContract';
import { useBNBContract } from '../hooks/useBNBContract';
import { useLPContract } from '../hooks/useLPContract'
import useWeb3  from "../hooks/useWeb3";
import moment from 'moment'
import numeral from 'numeral'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import ReactGA from 'react-ga'

export const  Index = () => {
  const homeRef = useRef()
  const buyRef = useRef()
  const chartRef = useRef()
  const aboutRef = useRef()
  const roadmapRef = useRef()
  const commuRef = useRef()
  const { myAccount } = useAccounts()
  const tokenContract = useThe7Contract()
  const lpContract =  useLPContract()
  const bnbContract = useBNBContract()
  const pancakeRouterContract = usePancakeRouterContract()
  const [tokenBalance, setTokenBalance] = useState(0)
  const [startUnlockTime, setStartUnlockTime] = useState('')
  const [endUnlockTime, setEndUnlockTime] = useState('')
  const [marketCap, setMarketCap] = useState(0)
  const [burnedToken, setBurnedToken] = useState(0)
  const [liquidity, setLiquidity] = useState(0)
  const [tokenPrice, setTokenPrice] = useState(0)
  const [unlockTime, setUnlockTime] = useState(moment.duration(0, 'seconds'))
  const [isDataFetched, setIsDataFetched] = useState(false)
  const [counter, setCounter] = useState(0)
  const [isUnlockTimeSet, setIsUnlockTimeSet] = useState(false)
  const [buyCountdown, setBuyCountdown] = useState(moment())
  const [holder, setHolder] = useState('0')
  const [isUnlock, setIsUnlock] = useState(false)
  const [web3Val] = useWeb3()
  const history = useHistory()
  const web3 = web3Val.web3;

  function goToPCS (){
    window.open(`https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb67B2d1180035B2a5CFCfE19C606C2c7576569bc`, "_blank")
  }
  function openGraph(){
    window.open(`https://dex.guru/token/${THE_7_ADDRESS}-bsc`, "_blank")
  }
  function manualConnectWeb3(){
    web3Val.initWeb3()
  }

  async function getBNBPrice(pancakeRouter) {
    const oneBnb = '0x' + (1*10**18).toString(16)
    const lpPair = (await pancakeRouter.methods.getAmountsOut(oneBnb, [WBNB_CONTRACT_ADDRESS, BUSD_CONTRACT_ADDRESS]).call())
    // console.log('lpPair', lpPair)
    // console.log('1 BNB is', lpPair[1]/lpPair[0], 'BUSD')
    return lpPair[1]/lpPair[0]
  }

  async function getTHE7Price(pancakeRouter, bnbPrice) {
    // console.log('bnb price  ', bnbPrice)
    const oneBnb = '0x' + (1*10**18).toString(16)
    const lpPair = await pancakeRouter.methods.getAmountsOut(oneBnb, [THE_7_ADDRESS,WBNB_CONTRACT_ADDRESS]).call()
    return ((lpPair[1]/lpPair[0])/10**9)*bnbPrice
  }
  const formatAddress = (acc) => {
    return `${acc.substr(0,4)}...${acc.substring(acc.length - 4, acc.length)}`
  }
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  }
  useEffect(() => {
    if(!isDataFetched){
      ReactGA.initialize('UA-198740041-1')
      ReactGA.pageview(window.location.pathname + window.location.search)
      axios.get(
          `https://raspy-term-5f23.dumm.workers.dev/`).then((resp) => {
            setHolder(resp.data.holder)
      }).catch((err) => {
        console.log('err ', err)
      }) 
      
      // const resp = await axios.get(
      //   'https://testnet.bscscan.com/token/0x09f484391a377dc76d8e92961b56bbad50b97fc0')
       
        // axios.get(`https://testnet.bscscan.com/token/${THE_7_ADDRESS}`, {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        // }).then(resp => {
        //     console.log('resp ')
        //     console.log(resp)
        // })
    }
    if(!myAccount || !lpContract || !tokenContract || !pancakeRouterContract || !bnbContract) return;
    fetchFromBlockchain(myAccount, tokenContract, lpContract, pancakeRouterContract, bnbContract)
  }, [web3, myAccount, lpContract, tokenContract, pancakeRouterContract, bnbContract])
  const fetchFromBlockchain = useCallback(async (myAccount , tokenContract, lp, pancakeRouter, bnbContract) => {
    const bal = await tokenContract.methods.balanceOf(myAccount).call()
    if(bal > 0){
      try{
        //lockTime time to start lock (unix)
        // seconds to unlock
        const lockTime = await tokenContract.methods.lockTimestamp(myAccount).call()
        const startUnlockTime = moment.unix(lockTime)
        // console.log('lockTimeStamp', startUnlockTime.format())
        const endUnlockTime = moment.unix(lockTime).add('1', 'days')
        // console.log('start', startUnlockTime.format())
        // console.log('end', endUnlockTime.format())
        setStartUnlockTime(startUnlockTime.format('dddd hh.mm A'))
        setEndUnlockTime(endUnlockTime.format('dddd hh.mm A'))
        let currentCountDown = moment().day(endUnlockTime.days())
        let s = moment().day(startUnlockTime.days())
        let sDate = s.format('YYYY-MM-DD')
        // console.log(endUnlockTime.format())

        let date = currentCountDown.format('YYYY-MM-DD')
        let time = endUnlockTime.format('HH:mm')
        let format = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm")
      
        // console.log('date ', currentCountDown.format('YYYY-MM-DD'))
        // console.log('time ', endUnlockTime.format('HH:mm'))
        // console.log('cd',currentCountDown.format())
        // console.log('f ',format.format())
        let secondCD = format.diff(moment(), 'seconds')
        // console.log('secondCD', secondCD)
        setBuyCountdown(moment.duration(secondCD, 'seconds'))
        const timeToUnlock = await tokenContract.methods.timeToUnlock(myAccount).call()
        // console.log('timeToUnlcok ', timeToUnlock)
        const formattedTimeToUnlock = moment.duration(timeToUnlock, 'seconds')
        const currentRoundStartLockTime = moment(`${sDate} ${time}`, "YYYY-MM-DD HH:mm")
        const currentRoundEndLockTime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm")
        const currentDate = moment()
        setUnlockTime(formattedTimeToUnlock)
        // console.log('currentDate', currentDate.format())
        // console.log('cur s round', currentRoundStartLockTime.format())
        // console.log('cur e round', currentRoundEndLockTime.format())
        // console.log(currentDate.isAfter(currentRoundStartLockTime))
        // console.log(currentDate.isBefore(currentRoundEndLockTime))
        if(currentDate.isSameOrAfter(currentRoundStartLockTime) && currentDate.isSameOrBefore(currentRoundEndLockTime)){
          setIsUnlock(true)
        }
        setIsUnlockTimeSet(true)
        // console.log('timeToUnlock day ', formattedTimeToUnlock.days(), ' hours ', formattedTimeToUnlock.hours(), 'minutes', formattedTimeToUnlock.minutes(), ' seconds ', formattedTimeToUnlock.seconds())
        // setUnlockTime()
      }catch(err){
        console.log(err)
      }
    }
  
    setTokenBalance(bal)
    const _firstBurnedToken = await tokenContract.methods.balanceOf('0x000000000000000000000000000000000000dead').call()
    const _burnedToken = await tokenContract.methods.totalFees().call()
    const _totalToken = await tokenContract.methods.totalSupply().call() 
    const totalBurned = parseInt(_burnedToken) +parseInt(_firstBurnedToken)
    setBurnedToken( totalBurned)
    const totalLpSupply = await lp.methods.totalSupply().call();
    if (totalLpSupply > 0) {
      const bnbPrice = await getBNBPrice(pancakeRouter)
      const the7Price = await getTHE7Price(pancakeRouter, bnbPrice)
      setMarketCap(((_totalToken - _burnedToken)/ 10**9) * the7Price)
      const bnbBalance = await bnbContract.methods.balanceOf(PANCAKE_LP_ADDRESS).call()
      const the7Balance = await tokenContract.methods.balanceOf(PANCAKE_LP_ADDRESS).call()
    //   console.log('bnbBalance is ', bnbBalance/ 10**18)
    //   console.log('the7Balance is', the7Balance/ 10**9)
    //   console.log('bnbBalance ', bnbBalance/ 10**18)
    // console.log('bnbLiq',(bnbBalance/ 10**18) * bnbPrice )
    // console.log('the7Liq', (the7Balance/ 10**9) * the7Price)
      setLiquidity(((bnbBalance/ 10**18) * bnbPrice) + ((the7Balance/ 10**9) * the7Price))
      setTokenPrice(the7Price)
    }
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => {
      if(isUnlockTimeSet){
        let updateUnlockTime = unlockTime.add('-1', 'seconds')
        let updateBuyCountdown = buyCountdown.add('-1', 'seconds')
        setUnlockTime(updateUnlockTime)
        setBuyCountdown(updateBuyCountdown)
      }
      setCounter(counter - 1)
    }, 1000);
    return () => clearTimeout(timer);
  }, [counter]);
  const scrollToView = (ref) => {
    window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop })
  }
  const redirectToIndex = () => {
    history.push('/whitepaper')
  }
  return (
    <div>
      <div className="landing-section" ref={homeRef}>
        <div className="xl:pl-16 xl:pr-16 pl-4 pr-4">
          <div className="pt-4 flex justify-between items-center">
            <div className="logo gradient-text xl:ml-32 text-2xl mr-10">
              COOLDOWN
            </div>
            <div className="hidden xl:flex menu-bar text-xl">
              <div className="cursor-pointer mr-8" onClick={() => scrollToView(homeRef)}>
                Home
              </div>
              <div className="cursor-pointer mr-8" onClick={() => scrollToView(buyRef)}>
                Buy
              </div>
              <div className="cursor-pointer mr-8" onClick={() => scrollToView(chartRef)}>
                Chart
              </div>
              <div className="cursor-pointer mr-8" onClick={() => scrollToView(aboutRef)}>
                About
              </div>
              <div className="cursor-pointer mr-8" onClick={() => scrollToView(roadmapRef)}>
                Roadmap
              </div>
              <div className="cursor-pointer mr-8" onClick={redirectToIndex}>
                Whitepaper
              </div>
              <div className="cursor-pointer mr-8" onClick={() => scrollToView(commuRef)}>
                Community
              </div>
            </div>
            <Button type='primary' onClick={manualConnectWeb3}>
              {myAccount ? formatAddress(myAccount) : 'Connect Wallet'}
            </Button>
          </div>
          <div className="landing-content w- flex flex-col items-center justify-center">
            <div className="logo gradient-text xl:text-9xl text-5xl xl:mt-32 mt-16">
              COOLDOWN
            </div>
            <div className="xl:ml-32 xl:mr-32">
              <div className="xl:text-xl text-md mt-8 mb-4 pl-8 pr-8">
                Cooldown is autonomous yield and liquidity generation protocol, fair launched Defi project on Binance Smart Chain. 
                On each transaction the protocol automatically distributes rewards to holders as well as auto-locks liquidity forever. 
                The main feature to reduce volatility is cooldown system that allow users to sell Cooldown token only once a week. 
              </div>
              <div className="mt-8 mb-4 xl:mb-0 flex justify-center">
                <DisplayButton value={`$${tokenPrice.toFixed(12)}`} type='primary' label='Current Value' />
                <DisplayButton value={holder} label='Holders' />
                <DisplayButton value={numeral(liquidity).format('$0[.]00')} type='primary' label='Liquidity' />
                <DisplayButton value={numeral(burnedToken / 10** 9).format('0 a').toUpperCase()} label='Burned' />
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="xl:pl-16 xl:pr-16 pl-2 pr-2">
        <div className="rounded-3xl gradient-button mt-16 mb-4 w-full text-center p-14 text-white font-normal">
          <label className="xl:text-2xl text-xl">
          Market Cap (USD)
          </label>
          <div className="text-5xl xl:text-7xl mt-2">
          {numeral(marketCap).format('$ 0,0[.]00')}
          </div>
        </div>
      </div>
      <div className="swap-section" ref={buyRef}> 
          <div className="pt-24 ">
            <div className="flex justify-center">
                <div className="xl:w-6/12">
                <div className="transparent-container">
                    <div className="transparent-content">
                        <div className="flex justify-between">
                            <Button type='primary' onClick={manualConnectWeb3}>
                              {myAccount ? formatAddress(myAccount) : 'Connect Wallet'}
                            </Button>
                            <Button>
                                <div className="flex items-center">
                                Token Address : {formatAddress(THE_7_ADDRESS)} <img onClick={copyToClipboard(THE_7_ADDRESS)} className="w-6 ml-1" src={CopyIcon} />
                                </div>
                            </Button>
                        </div>
                        <div className="mt-11">
                            <div className="bg-white rounded-3xl font p-6">
                                <span className="font-bold">
                                    Time to sell:
                                </span>
                                <span className="ml-8">
                                    {startUnlockTime && endUnlockTime ? 'Every' : ''} {startUnlockTime}- {endUnlockTime}
                                </span>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="bg-white rounded-3xl font p-6">
                                <span className="font-bold">
                                    {isUnlock ? 'Time Left' : 'Cooldown'}:
                                </span>
                                <span className="ml-8">
                                    {isUnlock  ? `${buyCountdown && buyCountdown.hours()} hours : ${buyCountdown && buyCountdown.minutes()} mins : ${buyCountdown && buyCountdown.seconds()}s` : `${unlockTime.days()} Days : ${unlockTime.hours()} hours : ${unlockTime.minutes()} mins : ${unlockTime.seconds()}s`}:
                                </span>
                            </div>
                        </div>
                        <div className="mt-5 text-sm">
                          *maximum trading per transaction : 0.5% of total supply ( 5,000,000,000,000)<br/>
                          *slippage should be 10-20 %
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <Button type="primary" onClick={goToPCS}>
                        <span className="text-xl font-bold">
                            Buy
                        </span>
                    </Button>
                </div>
                </div>
            </div>
          </div>
      </div>
      <div className="livechart gradient-button flex flex-col justify-center" ref={chartRef} onClick={openGraph}>
        <h2 className='text-5xl text-white text-center pt-14'>
          Live Chart
        </h2>
        <img className="mt-6 pb-20 pl-24 pr-24 cursor-pointer" src={LiveChart} />
      </div>
      <div className="learn-more" ref={aboutRef}>
        <div className="pl-16 pr-16">
          <div className="xl:text-5xl text-3xl text-center mt-11">
          Learn more about <span className="xl:text-5xl text-4xl logo gradient-text">COOLDOWN</span>
          </div>
          <div className="flex justify-center mt-20">
            <DescriptionBox img={System} label='The 7 System' />
            <DescriptionBox img={Tokenomic} label='Tokenomics' />
            <DescriptionBox img={Solution} label='Solution' />
          </div>
          <div className="content mt-20 xl:pl-48 xl:pr-48">
            <div className="content-header text-3xl pb-6 text-primary">
              How COOLDOWN system work?
            </div>
            <ul classsName="list-disc">
              <li>The aim of Cooldown system is to avoid a sudden price drop</li>
              <li>Cooldown tokens will be locked for 7 days, and users will be able to sell or transfer them once a week.</li>
              <li>After 7 days, Cooldown tokens can be sold or transferred to another address within 24 hours.</li>
              <li>Users can buy Cooldown tokens at any time, the selling time and Cooldown time will not change.</li>
              <li>In the other hand, once users sell all tokens, the selling time and Cooldown time will be reset.</li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
              Features
            </div>
            <ul classsName="list-disc ">
              <li>Fee per transaction : 10%.</li>
              <li>Distributed to holders : 1%</li>
              <li>Auto-locked in liquidity (Cooldown-BNB): 9% </li>
              <li>Anti whales : Transactions ( sell/buy/transfer ) that trade more than 0.5% of the total supply will be rejected</li>
              <li>Highly secured : To ensure the safety of the whole group, we have burned all of the Liquidity Pool tokens and renounced contract ownership. </li>
              <li>Fair launch </li>
            </ul>
            <div className="content-header text-3xl pt-6 pb-6 text-primary">
              Tokenomics
            </div>
            <ul classsName="list-disc ">
              <li>Total supply : 1,000,000,000,000,000 (100%)</li>
              <li>Burned supply : 50%</li>
              <li>Liquidity : 45%</li>
              <li>Operation/marketing : 5%</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="roadmap bg-gray-100 mt-10 xl:mt-0" ref={roadmapRef}>
        <div className="hidden xl:block">
          <div className="pl-16 pr-16 mt-20 pb-32 pt-20">
            <div className="gradient-text text-5xl text-center">
              Roadmap
            </div>
            <div className="block">
              <div class="arrow mt-8">
                <div class="line" />
                <div class="point" />
              </div>
            </div>
            <p>&nbsp;</p>
            <div className="flex">
              <div className="mr-5">
                <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button type="primary">
                  31 May 2021<br/>
                  Deployed token and contract ,
                  <br/>Liquidity locked , 
                  <br/>Burned 50% of total supply 
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button>
                  Q2 2021<br/>
                  Marketing launch<br/>
                  , Social media launch
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button type="primary">
                  Q2 2021<br/>
                  Coingecko & Coinmarketcap<br/>
                  listing
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button>
                  Q3 2021<br/>
                  COOLDOWN V.2 <br/>
                  ( lotto system ) <br/>
                </Button>
              </div>
              <div className="mr-5">
              <div class="vertical-arrow">
                  <div class="vertical-line" />
                  <div class="vertical-point" />
                </div>
                <Button type="primary">
                  Coming soon
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="block xl:hidden" ref={roadmapRef}>
          <div className="bg-gray pt-10 pb-20 pl-4 pr-4 relative">
            <div className="gradient-text text-5xl text-center mb-20">
              Roadmap
            </div>
            <div class="vertical-arrow-no-height h-4/5 absolute">
              <div class="vertical-line" />
              <div class="vertical-point" />
            </div>
            <div className="mb-10">
              <Button type="primary">
                1 May 2021<br/>
                Deployed token and contract ,
                <br/>Liquidity locked , 
                <br/>Burned 50% of total supply 
              </Button>
            </div>
            <div className="mb-10">
              <Button>
                Q2 2021<br/>
                Marketing launch<br/>
                , Social media launch
              </Button>
            </div>
            <div className="mb-10">
              <Button type="primary">
                Q2 2021<br/>
                Coingecko & Coinmarketcap<br/>
                listing
              </Button>
            </div>
            <div className="mb-10">
              <Button>
                Q2 2021<br/>
                COOLDOWN V.2 <br/>
                ( lotto system ) <br/>
              </Button>
            </div>
            <div className="mb-10">
              <Button type="primary">
                coming soon
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="contact" ref={commuRef}>
        <div className="gradient-button text-4xl text-white p-12 rounded-xl">
          Join our community
        </div>
        <div className="pl-16 pr-16 mt-20">
          <div className="xl:pl-48 xl:pr-48">
            <span className="font-bold text-xl text-gray-700">
              Get in touch
            </span>
            <div className="flex justify-center items-center mt-12">
                <a className="cursor-pointer" href="mailto:info@cooldown.finance" target="_blank">
                  <img className="mr-4 contact-item" src={Mail} />
                </a>
                <a className="cursor-pointer" href="https://twitter.com/CooldownF" target="_blank">
                  <img className="mr-4 contact-item" src={Twitter} />
                </a>
                <a className="cursor-pointer" href="https://t.me/cooldownfinance" target="_blank">
                <img className="mr-4 contact-item" src={Telegram} />
                </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer mt-10 pt-6 pb-6 font-semibold bg-gray-300 text-center text-gray-500">
        COOLDOWN Copyright 2021 - All Rights Reserved
      </div>
    </div>
  );
}

