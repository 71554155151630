import React from 'react'

export const Button = ({ children, type, onClick }) => {
  const buttonTypeClass = type === 'primary' ? 'text-white gradient-button' : 'border  gradient-border text-black'
  return (
    <div onClick={onClick} className={`cursor-pointer rounded-full xl:pt-4 xl:pb-4 xl:pl-9 xl:pr-9 p-2 text-sm  text-center ${buttonTypeClass}`}>
        <span >
            {children}
        </span>
    </div>
  )
}

